import {
  PermissionType,
  EngineConfig,
  Equity,
  StorageUsageCategory,
  EngineConfigKeyType,
  MixImageGenre,
  SketchGenre,
  MixGenreKeyType,
  SketchGenreKeyType,
  ProArtFilterGenreKeyType,
  EquityType,
  ScaleType
} from 'models/ApiModels'
import _toNumber from 'lodash/toNumber'
import freeformImg from 'assets/images/freeform.png'
import styleMigrationImg from 'assets/images/style_migration.png'
import emojiSpritesImage from 'assets/images/emoji-datasource-apple-playform-64.png'

import startProjectSketchImageImg from 'assets/images/startpage-project/start-project-sketch-image.png'
import startProjectFreeformImg from 'assets/images/startpage-project/start-project-freeform.png'
import startProjectCreativeMorphImg from 'assets/images/startpage-project/start-project-creative-morph.png'

import FilterHdrIcon from '@mui/icons-material/FilterHdr'
import FaceIcon from '@mui/icons-material/Face'
import LocalFloristIcon from '@mui/icons-material/LocalFlorist'
import GrainIcon from '@mui/icons-material/Grain'
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot'
import FashionIcon from 'components/Icon/FashionIcon'
import FreeformIcon from 'components/Icon/FreeformIcon'
import RoundRoundIcon from 'components/Icon/RoundRoundIcon'
import SquareRoundIcon from 'components/Icon/SquareRoundIcon'

export const BREAKPOINTS_SMALLSCREEN_UP = ['lg', 'xl', 'md', 'sm']
export const BREAKPOINTS_MEDIUMSCREEN_UP = ['lg', 'xl', 'md']
export const BREAKPOINTS_LARGESCREEN_UP = ['lg', 'xl']
export const BREAKPOINTS_LARGESCREEN_DOWN = ['xs', 'sm', 'md']
export const BREAKPOINTS_MEDIUMSCREEN_DOWN = ['xs', 'sm']
export const BREAKPOINTS_EXTRASMALL = ['xs']

export const urlParam = {
  GENRE: 'genre',
  NAME: 'name',
  STYLE: 'style'
}

export const INPUT_TYPE = {
  mouse: 'mouse',
  touch: 'touch'
}

/* Local storage key */
export const LSKey = {
  CURRENT_COLLECTION: 'a00fd7b2674aa32294f49213e3abbec9',
  INSPIRATION_IMAGE_SET: `INSPIRATION_IMAGE_SET_ID`,
  SET_STYLE_IMAGE_SET: `SET_STYLE_IMAGE_SET_ID`,
  USER_DATA: `56491f2e1c74898e18bb6e47d2425b19`,
  EMAIL_FOR_LINK_LOGIN: '7569dfe46f42af21c8e117815819352e',
  EMAIL_FOR_LINK_LOGIN_LOGGING: 'af11f68ae4117f16fda5d08cf96a80c4',
  REDIRECT_AFTER_LOGIN: '6df645d2f2a7eac9e8aa99442163e9c4',
  QUERY_PARAM: '4d2d549a09106546453b9819f77d4f7f',
  REDUX: 'PLAYFORM_REDUX',
  PROJECT_PAGE_SOURCE: 'f2a614a093cfca0f925f9ea2e98c45dd',
  COLLECTION_ADDED_TO_NEW_PROJECT: 'b98e1f1a28f13bb7af216445dbb2f7f9',
  COLLECTION_ADDED_TO_EXISTING_PROJECT: 'a143cc3e5b84b0309af7bfd3d220f3ce',
  UNVERIFIED_PHONE_DATA: '7e3277b62079af9a3721d424c5ac156a',
  DISMISS_MYPROJECT_UPSELL: '41f44713fd79c4e3a780e7b81c4d002c',
  REFERRAL_OPEN_FIRST_LOAD: '98bab1c56d3d711025f961974c58db60',
  REFERRAL_TEST_AUTH: '80675fa1cbcc918e139b14e98f5cb82e',
  PENDING_CREDENTIAL: 'b806afd58ed5d081d1f51e1c0f248237',
  USER_DEBUG_EMAIL: 'b8cbde65f7aab82bc5c4f102160e4bed'
}
export const name = {
  DEFAULT_COLLECTION_NAME: 'Untitled Collection',
  DRAFT_COLLECTION_NAME: 'Draft Collection',
  DEFAULT_TRAIN_PROJECT_NAME: 'Untitled Train Project',
  DEFAULT_SKETCH_PROJECT_NAME: 'Untitled Sketch Project',
  DEFAULT_MIX_IMAGE_PROJECT_NAME: 'Mix Image Project',
  DEFAULT_STYLIZE_PROJECT_NAME: 'Stylize Filter Project',
  FIRST_TIME_DEFAULT_PROJECT_NAME: 'My First Project',
  FIRST_TIME_DEFAULT_SKETCH_PROJECT_NAME: 'My First Sketch Project',
  DEFAULT_SKETCH_TO_IMAGE_PROJECT_NAME: 'Sketch To Image Project'
}

export const values = {
  WEI_SCALE: 1e18,
  DISABLE_PLUS_SUBSCRIPTION: false,
  MAX_UPSCALE_SIZE: 12 * 1024,
  SOURCE_FROM_PRETRAINED: 'pretrained_model',
  MIN_UPLOADED_IMAGE: 30,
  MAX_PROJECT_NAME: 50,
  UPLOAD_ACCEPT: 'image/jpeg, image/png',
  UPLOAD_ACCEPT_HEIC: 'image/jpeg, image/png, image/heic, image/heif',
  MAX_IMAGE_SIZE: 10485760,
  MAX_IMAGE_SIZE_MEGABYTE: '10 MB',
  MIN_PUBLIC_COLLECTION_IMAGE: 5,
  ADD_CREDIT_PRODUCT_CODE: 'playform_credit',
  ALIPAY_CHANNEL_NAME: 'alipay',
  COINBASE_CHANNEL_NAME: 'coinbase',
  BRAINTREE_CHANNEL_NAME: 'braintree',
  PAYPAL_CHANNEL_NAME: 'paypal',
  PLAYFORM_CONTACT_EMAIL: 'playform@playform.io',
  MAX_TRAINING_TIME: 300,
  PRO_FILTER_IMAGE_MAX_SIZE: 1500,
  TEXT_TO_IMAGE_BASE_SIZE: 640,
  SKETCH_IMAGE_MAX_SIZE: 1500,
  DATE_FORMAT: 'MM/DD/YYYY',
  TIMESTAMP_FORMAT: 'MM/DD/YYYY, h:mm A',
  TIMESTAMP_FORMAT_NO_YEAR: 'MMM DD, h:mm A',
  TIMESTAMP_FORMAT_TIME_ONLY: 'h:mma',
  TIMESTAMP_FORMAT_2: 'MMMM DD YYYY, h:mm A',
  TIMESTAMP_FORMAT_2_NO_TIME: 'MMMM DD, YYYY',
  BASE_CURRENCY: 'USD',
  DIALOG_TRANSITION_DURATION: 500,
  MAXIMUM_CLIP_DURATION: 60,
  MAXIMUM_KEYFRAME_COUNT: 60,
  VIDEO_CLIP_PREVIEW_FRAME_RATE: 10,
  DEFAULT_TRANSITION_DURATION: 300,
  FEATURED_POST_LIMIT_LOGIN_USER: _toNumber(
    process.env.REACT_APP_FEATURED_POST_LIMIT_LOGIN_USER ?? 6
  ),
  REACT_APP_TWITTER_CONVERSION_ID: process.env.REACT_APP_TWITTER_CONVERSION_ID ?? '',
  HIDE_STYLIZE_PROJECT: process.env.REACT_APP_HIDE_STYLIZE_PROJECT === 'TRUE',
  HIDE_FACEMIX_PROJECT: process.env.REACT_APP_HIDE_FACEMIX_PROJECT === 'TRUE',
  HIDE_SKETCH_PROJECT: process.env.REACT_APP_HIDE_SKETCH_PROJECT === 'TRUE',
  HIDE_TEXTTOIMAGE_PROJECT: process.env.REACT_APP_HIDE_TEXTTOIMAGE_PROJECT === 'TRUE',
  HIDE_MIX_PROJECT_SKETCH: process.env.REACT_APP_HIDE_MIX_PROJECT_SKETCH === 'TRUE',
  DEFAULT_SELECTED_STYLE_ID: process.env.REACT_APP_DEFAULT_SELECTED_STYLE_ID,
  TWITTER_URL: 'twitter.com/',
  INSTAGRAM_URL: 'instagram.com/',
  MINIMUM_SELL_NFT_IMAGE: 10,
  ENABLE_SELL_NFT: process.env.REACT_APP_ENABLE_SELL_NFT,
  BALANCE_LIMIT_THRESHOLD: _toNumber(process.env.REACT_APP_BALANCE_LIMIT_THRESHOLD || 0) || 0
}

const PROJECT_CATEGORY_LINK: { [key in EngineConfigKeyType]?: string } = {
  morph: 'https://playform.gitbook.io/playform/processes/creative-morph',
  freeform: 'https://playform.gitbook.io/playform/processes/freeform',
  progressive: 'https://playform.gitbook.io/playform/processes/freeform/progressive',
  freeform_high_res: 'https://playform.gitbook.io/playform/processes/freeform',
  style_transfer: 'https://playform.gitbook.io/playform/processes/style-transfer',
  morph_512: 'https://playform.gitbook.io/playform/processes/creative-morph'
}
export const link = {
  DELETE_ACCOUNT: 'https://www.surveymonkey.com/r/FC7B79K',
  DOCUMENTATION: 'https://playform.gitbook.io/playform/',
  SLACK:
    'https://join.slack.com/t/playform-io/shared_invite/enQtNjcwOTM4MDQ4ODY1LWY5M2EyODE0OGY4OTliZGZjZGUwYjAzMjY0MTMzNzIxZjk3YmZmMjRmMzZhY2NmNjg2MGQ3ODYzNzU5MzczYjE',
  OUR_DATASET: 'https://www.dropbox.com/sh/wcquw1fvcnl0o9i/AAAlEgh4pVI1PdZUNb2-3njNa?dl=0',
  USE_AN_EXTENSION:
    'https://chrome.google.com/webstore/detail/image-downloader/cnpniohnfphhjihaiiggeabnkjhpaldj',
  TOC: 'https://www.playform.io/t-c',
  PRIVACY_POLICY: 'https://www.playform.io/t-c',
  LEARN_MORE: 'https://www.playform.io/',
  PROJECT_CATEGORY_LINK,
  LEARN_MORE_STUDIO: 'https://www.playform.io/studio',
  FAQS: 'https://playform.gitbook.io/playform/faqs',
  DISCORD: 'https://discord.gg/uhAMdeuu5H',
  ARTMINE_LEARN_MORE: 'https://www.playform.io/artmine',
  ARTMINE_URL: process.env.REACT_APP_ARTMINE_URL,
  ARTMINE_CHANGE_GAS_PRICE: 'https://metamask.zendesk.com/hc/en-us/articles/360022895972',
  CREATIVE_SURVEY_AIRTABLE: 'https://airtable.com/embed/shrbMEP0XpNBtgpbx',
  DELETE_ACCOUNT_AIRTABLE: 'https://airtable.com/embed/appvrbgeqp7PaaNkR/pagFHQQcQSPgVhEQF/form'
}

export const REFRESH_UNFINISHED_UPLOAD_INTERVAL = 10000
export const REFRESH_ARTMINE_PUBLISHING_INTERVAL = 15000
export const REFRESH_PROJECT_INTERVAL = 30000
export const REFRESH_TOKEN_INTERVAL = 10 * 60 * 1000 * 5.5 // force refresh every 55 minutes;
export const REFRESH_NOTIFICATION_INTERVAL = 30000
export const REFRESH_NOTIFICATION_INTERVAL_OPENED = 2000
export const EMPTY_OBJECT: any = {}
export const EMPTY_ARRAY: any = []

export const errorMessage = {
  ERROR_UPLOAD_TOO_LARGE: {
    content: `Image is too large (max: ${values.MAX_IMAGE_SIZE_MEGABYTE})`
  },
  ERROR_UPLOAD_UNKNOWN: {
    content: 'Caused by some unknown error'
  },
  ERROR_COLLECTION_UPLOADING: {
    title: `Unable To Upload`,
    content: 'You have another upload in progress. Please wait until it finished.'
  },
  ERROR_COLLECTION_NAME_EMPTY: {
    content: `Enter a collection name`
  },
  ERROR_FIELD_EMPTY: {
    content: `This field may not be blank`
  },
  ERROR_UNABLE_ADD_OLD_SNAPSHOT_IMAGE: {
    content: 'Unable to add image from older snapshot'
  }
}

export const STORAGE_USAGE_DATA: { [key in StorageUsageCategory]: { title: string } } = {
  sketch: {
    title: 'Sketch'
  },
  project: {
    title: 'Training'
  },
  lse: {
    title: 'Mix Image'
  }
}

const HIDDEN_ENGINE = (process.env.REACT_APP_HIDDEN_ENGINE_CONFIG ?? '').split(',')

export const DISABLE_ADVANCED_SETTING =
  process.env.REACT_APP_TRAINING_PROJECT_DISABLE_ADVANCED_SETTING === 'TRUE'

export const EngineConfigAdditionalData: {
  [key in EngineConfigKeyType]: Pick<
    EngineConfig,
    'descriptions' | 'img_src' | 'hide' | 'can_mix' | 'can_video' | 'thumbnail_description'
  >
} = {
  morph: {
    hide: HIDDEN_ENGINE.includes('morph'),
    descriptions: [
      'Create hybrid results by translating one image set to another.',
      'Two sets of 30+ images each'
    ],
    img_src: styleMigrationImg,
    thumbnail_description: 'Create exploratory mixtures when you go from one image set to another.'
  },
  freeform: {
    hide: HIDDEN_ENGINE.includes('freeform'),
    descriptions: ['Generate new images from your inspirations.', 'One set of 30+ images'],
    img_src: freeformImg,
    thumbnail_description: 'Train your own AI GAN generator based on your own images.'
  },
  diffusion_freeform: {
    hide: HIDDEN_ENGINE.includes('diffusion_freeform'),
    descriptions: ['Generate new images from your inspirations.', 'One or more images'],
    img_src: freeformImg,
    thumbnail_description: 'Generate new images based on your own inspirations, no prompt needed.'
  },
  progressive: {
    hide: HIDDEN_ENGINE.includes('progressive'),
    descriptions: [
      'Use inspirations to generate results that grow larger over time to 1024px with wavy effects.',
      'One set of 30+ images'
    ],
    img_src: freeformImg
  },
  freeform_high_res: {
    can_mix: true,
    can_video: true,
    hide: HIDDEN_ENGINE.includes('freeform_high_res'),
    descriptions: ['Generate new 1024px images from your inspirations.', 'One set of 30+ images'],
    img_src: freeformImg,
    thumbnail_description: 'Train your own AI GAN generator based on your own images.'
  },
  style_transfer: {
    hide: HIDDEN_ENGINE.includes('style_transfer'),
    descriptions: ['Use your custom style on an inspiration image set.', 'Two sets of images'],
    img_src: styleMigrationImg,
    thumbnail_description:
      'Generates stylized images that use the compositions of the first set and the style of the second.'
  },
  morph_512: {
    hide: HIDDEN_ENGINE.includes('morph_512'),

    descriptions: [
      'Create larger hybrid results by translating one image set to another.',
      'Two sets of 30+ images each'
    ],
    img_src: styleMigrationImg,
    thumbnail_description: 'Create exploratory mixtures when you go from one image set to another.'
  },
  freeform_high_res_2: {
    hide: HIDDEN_ENGINE.includes('freeform_high_res_2'),
    descriptions: [],
    img_src: styleMigrationImg,
    thumbnail_description: 'Train your own AI GAN generator based on your own images.'
  },
  freeform_high_res_v2: {
    can_mix: true,
    can_video: true,
    hide: HIDDEN_ENGINE.includes('freeform_high_res_v2'),
    descriptions: [],
    img_src: styleMigrationImg,
    thumbnail_description: 'Train your own AI GAN generator based on your own images.'
  }
}

export const MIX_GENRE_ADDITIONAL_DATA: {
  [key in MixGenreKeyType]: Pick<MixImageGenre, 'icon' | 'desc'>
} = {
  face: {
    icon: FaceIcon,
    desc: 'Advanced Face Mixing and Morphing'
  }
  // mixBigGan: {
  //   icon: EmojiNatureIcon
  // },
  // mixArtisticLandscapes: {
  //   icon: FilterHdrIcon
  // },
  // mixPhotorealisticLandscapes: {
  //   icon: ImageIcon
  // },
  // mixFashion: {
  //   icon: FashionIcon
  // }
}
export const STYLIZE_GENRE_ADDITIONAL_DATA: {
  [key in ProArtFilterGenreKeyType]: Pick<MixImageGenre, 'icon' | 'desc'>
} = {
  nst: {
    icon: RoundRoundIcon,
    desc: 'Transform an uploaded image to replicate any style of your choice'
  },
  mrf: {
    icon: RoundRoundIcon,
    desc: 'Transform an uploaded image to replicate any style of your choice'
  }
}

export const SKETCH_GENRE_ADDITIONAL_DATA: {
  [key in SketchGenreKeyType]: Pick<SketchGenre, 'icon' | 'desc'>
} = {
  still_life_2: {
    icon: LocalFloristIcon
  },
  abstract: {
    icon: ScatterPlotIcon
  },
  fashion: {
    icon: FashionIcon
  },
  human_face: {
    icon: FaceIcon
  },
  amorphous_face: {
    icon: GrainIcon
  },
  still_life: {
    icon: LocalFloristIcon
  },
  landscape: {
    icon: FilterHdrIcon
  }
}

export const PROJECT_CATEGORY_ADDITIONAL_DATA: {
  [key in EngineConfigKeyType]: Pick<EngineConfig, 'icon'>
} = {
  morph: {
    icon: SquareRoundIcon
  },
  freeform: {
    icon: FreeformIcon
  },
  diffusion_freeform: {
    icon: FreeformIcon
  },
  progressive: {
    icon: RoundRoundIcon
  },
  freeform_high_res: {
    icon: FreeformIcon
  },
  style_transfer: {
    icon: RoundRoundIcon
  },
  morph_512: {
    icon: SquareRoundIcon
  },
  freeform_high_res_2: {
    icon: FreeformIcon
  },
  freeform_high_res_v2: {
    icon: FreeformIcon
  }
}

export const START_PROJECT_OPTIONS: {
  id: string
  name?: string
  subtitle: string
  caption: string
  descriptions?: string[]
  cover: string
}[] = [
  {
    id: 'sketch_to_image',
    name: 'Sketch to Image',
    subtitle: '5 MIN PROJECT',
    caption: 'Choose a genre, style, and draw to generate images instantly.',
    cover: startProjectSketchImageImg
  },
  {
    id: 'morph_512',
    subtitle: '20 MIN PROJECT',
    caption: 'Create hybrid results by translating one image set to another',
    cover: startProjectCreativeMorphImg
  },
  {
    id: 'freeform_high_res',
    subtitle: '1 hour+ PROJECT',
    caption: 'Generate amalgamations of a single dataset.',
    descriptions: ['Generate new images from your inspirations.', 'One set of 30+ images'],
    cover: startProjectFreeformImg
  }
]

export const TutorialVideo = {
  generate: process.env.REACT_APP_VIDEO_TUTORIAL_GENERATE || '',
  shareAPost: process.env.REACT_APP_VIDEO_TUTORIAL_SHAREAPOST || '',
  sellNft: process.env.REACT_APP_VIDEO_SELLNFT || ''
}
export const UIConfig = {
  MIX_IMAGE_DISABLE_UPLOAD_TAB:
    process.env.REACT_APP_MIX_IMAGE_DISABLE_UPLOAD_TAB === 'TRUE' ? true : false,
  MIX_IMAGE_DISABLE_EXPLORE_TAB:
    process.env.REACT_APP_MIX_IMAGE_DISABLE_EXPLORE_TAB === 'TRUE' ? true : false
}

export const LEGACY_PROJECT_CATEGORY_TITLE = 'Legacy Model'

export const permissionKey: { [key: string]: PermissionType } = {
  CREATE_FREEFORM_PROJECT: 'playform.create_freeform_project'
}

export const SubscriptionData: { [key in Equity['type']]: Partial<Equity> } = {
  free: {
    typeName: 'Free',
    typeTitle: 'Pay As You Go',
    typeSubtitle: 'Explore AI Art'
  },
  plus: {
    typeName: 'Plus',
    typeTitle: 'Plus',
    typeSubtitle: 'Experiment with AI Art'
  },
  pro: {
    typeName: 'Pro',
    typeTitle: 'Pro',
    typeSubtitle: 'Pro-level AI Art'
  },
  studio: {
    typeName: 'Studio',
    typeTitle: 'Studio',
    typeSubtitle: 'Artist'
  }
}

export const SubscriptionProducts = {
  PLUS_MONTHLY: 'playform_plus_monthly',
  PLUS_YEARLY: 'playform_plus_yearly',
  PRO_MONTHLY: 'playform_pro_monthly',
  PRO_YEARLY: 'playform_pro_yearly',
  STUDIO_MONTHLY: 'playform_studio_monthly',
  STUDIO_YEARLY: 'playform_studio_yearly'
} as const

export const emojiSpritesImg = emojiSpritesImage

export type emojiDataType = {
  x: number
  y: number
}

export const UpscaleDownloadPricingConfig: {
  [key in EquityType]: {
    freeScale: ScaleType[]
    disabledScale: ScaleType[]
  }
} = {
  free: { freeScale: [], disabledScale: [6, 8, 10, 12] },
  plus: { freeScale: [2, 4], disabledScale: [] },
  pro: { freeScale: [2, 4], disabledScale: [] },
  studio: { freeScale: [2, 4], disabledScale: [] }
}

export const PAID_DOWNLOAD_LIST: EquityType[] = ['free', 'plus']
export const NO_BATCH_DOWNLOAD_SUPPORT: EquityType[] = ['free', 'plus']
export const NO_FREE_UPSCALE_4X_SUPPORT: EquityType[] = ['free']
export const NO_FREE_UPSCALE: EquityType[] = ['free']
export const NO_ACCESS_T2I_ADVANCED_OPTIONS: EquityType[] = ['free', 'plus']
// export const NO_ACCESS_ST2I_ADVANCED_OPTIONS: EquityType[] = ['free', 'plus']
export const NO_ACCESS_ST2I_ADVANCED_OPTIONS: EquityType[] = [] //Give all access to advanced
export const NO_ACCESS_GENERICAPP_SUBSCRIPTION: EquityType[] = ['free']
export const NO_ACCESS_MIXVIDEO_GENERATE: EquityType[] = ['free']

export const emojisData: { [key: string]: emojiDataType } = {
  clap: {
    x: 1,
    y: 1
  },
  triumph: {
    x: 1,
    y: 2
  },
  open_mouth: {
    x: 1,
    y: 3
  },
  thinking_face: {
    x: 1,
    y: 4
  },
  zany_face: {
    x: 1,
    y: 5
  },
  smiling_with_hearts: {
    x: 1,
    y: 6
  },
  smiling_face_with_3_hearts: {
    x: 1,
    y: 6
  }
}

export const NOTIFICATION_TEMPLATE_TITLE_MAP: Record<string, string> = {
  platform_news: 'Feature Updates and Announcements',
  referral_reward_success: 'Referral Reward',
  credits_near_expire: 'Near Expired Credit Notice',
  new_user_reactivation: '',
  new_user_no_engagement: '',
  change_email: '',
  user_verify: '',
  new_user_welcome: '',
  post_like: 'Somebody Like to Your Post',
  post_comment: 'Somebody Comment on Your Post'
} as const
